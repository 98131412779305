import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import parse from 'html-react-parser'

function Mission() {
  const [activeTab, setActiveTab] = useState(0)

  const [firstTabChange, setFirstTabChange] = useState(false)

  // const [currentNavValue, setCurrentNavValue] = useState("MANIFESTO")

  // const setActiveAndCurrentTab = (value, title) => {
  //   setActiveTab(value)
  //   setFirstTabChange(true)
  // }

  // const setActiveAndCurrentTabKD = (value, title) => {
  //   setActiveTab(value)
  //   setCurrentNavValue(title)
  //   setFirstTabChange(true)
  // }

  return (
    <>
      <StaticQuery
        query={graphql`
          query CareersMission {
            SuperOps {
              featureParentDetails(where: { page: "Careers" }) {
                title
                heading
                content {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <section className="fe-gl-content-outer fe-pr-content-outer">
            <center>
              <section className="fe-gl-content-inner">
                <Row className="fe-gl-content">
                  <Col lg={3}>
                    <Slide delay="200">
                      <div className="fpd-wrap fe-gl-nav-dsk">
                        {data.SuperOps.featureParentDetails.map((fpd, id) => {
                          return (
                            <div
                              className={`fgl-nav ${id === activeTab &&
                                "active"}`}
                              onClick={() => setActiveTab(id)}
                              onKeyDown={() => setActiveTab(id)}
                              role="button"
                              tabIndex={0}
                            >
                              <p>{fpd.title}</p>

                              <div className="at-line" />
                            </div>
                          )
                        })}
                      </div>
                    </Slide>
                  </Col>
                  <Col lg={9} className="fe-gl-pr">
                    {data.SuperOps.featureParentDetails.map((fd, i) => {
                      return (
                        <div
                          className="fe-hide"
                          id={i}
                          style={{
                            display: activeTab === i ? "block" : "none",
                          }}
                        >
                          <Fade
                            duration={2000}
                            collapse={firstTabChange}
                            when={activeTab === i}
                          >
                            <div className="fe-gl-details crs-fe-gl prelative">
                              <h2>{fd.heading}</h2>
                              <div className="p16">
                                {parse(fd.content.html)}
                              </div>
                            </div>
                          </Fade>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </section>
            </center>
          </section>
        )}
      />
    </>
  )
}

export default Mission
